const GATEWAY_URL = "https://core-gateway-dev.gs1pt.org/"
const BASE_ROUTE = GATEWAY_URL + 'api/';
const USER_ID_EVENT = 2851;
const USER_ID_TRAINING = 2852;

const ROUTES = {
  blob: BASE_ROUTE + 'forms/blob/',
  training: BASE_ROUTE + 'forms/training/',
  enrollTraining: (id: number) => ROUTES.training + id + '/enroll',
  event: BASE_ROUTE + 'forms/event/',
  settingMaintenance: BASE_ROUTE + 'forms/settings/maintenance',
  enrollEvent: (id: number) => ROUTES.event + id + '/enroll',
}

export const environment = {
  production: false,
  hmr: false,
  serviceworker: true,
  gatewayUrl: GATEWAY_URL,
  routes: ROUTES,
  name: "development",
  userIdEvent: USER_ID_EVENT,
  userIdTraining: USER_ID_TRAINING,
};

